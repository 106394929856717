var indexReload = 0;
var loadNumber = 4;
var preventUnload = false;

(function ($) {
    // define window width for later usage
    var windowWidth = 0;

    // save initial window width
    $(window).on('load', function() {
        windowWidth = $(window).innerWidth();
    });

    $('document').ready(function() {

        $('#webticker').webTicker({
            speed: 50, //pixels per second
            height: "18px", // The height of the ticker element. The string value needs to include the unit
            direction: "left", //if to move left or right
            moving: true, //weather to start the ticker in a moving or static position
            startEmpty: false, //weather to start with an empty or pre-filled ticker
            duplicate: true, //if there is less items then visible on the ticker you can duplicate the items to make it continuous
            rssurl: false, //only set if you want to get data from rss
            rssfrequency: 0, //the frequency of updates in minutes. 0 means do not refresh
            updatetype: "reset" //how the update would occur options are "reset" or "swap"
        });

        $('[data-toggle="tooltip"]').tooltip();

        $('select.select2').select2({
            minimumResultsForSearch: 10,
            allowClear: true
        });

        $('#practice_form_submissionFrom_month, #practice_form_submissionUntil_month').on('change', function() {
            var currentId = $(this).attr('id');
            currentId = currentId.replace('practice_form_', '');
            currentId = currentId.replace('_month', '');

            var currentVal = parseInt($(this).val());

            $('#practice_form_' + currentId + '_day').val(null).trigger('change');

            if (currentVal > 0) {
                $('#practice_form_' + currentId + '_day').val(1).trigger('change');
            }
        });

        resizeLoginLayerContent();

        // collapse header after 50px scroll distance
        $(window).on("scroll touchmove", function() {
            if($('.startpage-layout').length <= 0) {
                $('.overview .navigation-left, .default .navigation-left').toggleClass('collapsed', $(document).scrollTop() > 50);
                $('.ad-box .column').toggleClass('collapsed', $(document).scrollTop() > 50);
                $('.login-layer-container').toggleClass('header-collapsed', $(document).scrollTop() > 50);

                correctLoginLayer();

                if ($('.in-view-checker').length > 0 && $('.in-view-checker').isVisible()) {
                    indexReload++;
                    $('.in-view-checker').hide();
                    loadMoreResults($(ajaxLazyloadForm), ajaxLazyloadUri, indexReload, loadNumber, $('.later-elements'));
                }
            }
        });

        $('#fos_user_registration_form_practiceAddressLikePersonAddress, #pvp_user_edit_practiceAddressLikePersonAddress').change(function() {
            if($(this).is(":checked")) {
                $('#fos_user_registration_form_practiceStreet, #pvp_user_edit_practiceStreet').val($('#fos_user_registration_form_contactStreet, #pvp_user_edit_contactStreet').val());
                $('#fos_user_registration_form_practiceZipCode, #pvp_user_edit_practiceZipCode').val($('#fos_user_registration_form_contactZipCode, #pvp_user_edit_contactZipCode').val());
                $('#fos_user_registration_form_practiceCity, #pvp_user_edit_practiceCity').val($('#fos_user_registration_form_contactCity, #pvp_user_edit_contactCity').val());
                $("#fos_user_registration_form_practiceCountry, #pvp_user_edit_practiceCountry").val($("#fos_user_registration_form_contactCountry, #pvp_user_edit_contactCountry").val()).trigger('change');
            }
        });

        if($('.startpage-layout').length <= 0) {
            correctLoginLayer();
        }

        correctAdBanner();

        // open login panel
        $('.link-open-login-panel').on("click", function(e) {
            e.stopPropagation();
            e.preventDefault();

            if($(this).hasClass('login-panel-open')) {
                $(this).text($(this).data('text'));
            } else {
                $(this).data('text', $(this).text());
                $(this).text('');
            }

            $(this).toggleClass('login-panel-open');
            $('#login-layer').toggleClass('open');

        });

        // open login panel in-page link
        $(document).on('click', '.open-login', function(e) {
            e.stopPropagation();
            e.preventDefault();

            $('.link-open-login-panel').trigger('click');
        });

        $(document).on('click', function (e) {
            var loginPanelLink = $('.link-open-login-panel');
            var loginLayer = $('#login-layer');

            if(loginPanelLink.hasClass('login-panel-open')) {
                if ($(e.target).closest('#login-layer').length <= 0) {
                    loginPanelLink.toggleClass('login-panel-open');
                    loginLayer.toggleClass('open');

                    loginPanelLink.text(loginPanelLink.data('text'));
                }
            }
        });

        $('.carousel').carousel({
            interval: 6000
        });

        fixMobileAccordion();

        // Accordion functionality
        $('a[data-toggle="collapse"]').on("click",function() {
            var target = $(this).attr("href");
            var targetHeading = '#' + $(target).attr("aria-labelledby");
            //console.log(targetHeading);
            // Detect target status
            if($(target).hasClass("in")) {
                // Hide target content
                $(target).collapse("hide");
                $(targetHeading).removeClass("active");
            } else {
                // Hide everything else
                $('a[data-toggle="collapse"]').each(function(){
                    var target = $(this).attr('href');
                    var targetHeading = '#' + $(target).attr("aria-labelledby");
                    $(target).collapse('hide');
                    $(targetHeading).removeClass("active");
                });
                // Display target content
                $(target).collapse("show");
                $(targetHeading).addClass("active");
            }
        });

        $('.login-form').on('submit', function(e){
            e.preventDefault();
            var form = $(this);

            addAjaxLoader($('#login-layer').find('.login-layer'));

            $.ajax({
                type        : form.attr( 'method' ),
                url         : form.attr( 'action' ),
                data        : form.serialize(),
                dataType    : "json",
                success     : function(data, status, object) {
                    //if(data.error) $('.error').html(data.message);
                    //console.log(data);
                    if(data.error) {
                        $('#login-layer').addClass('login-error');
                        $('#login-layer-mobile').addClass('login-error');
                        form.find('#password').val('');
                    } else {
                        var loginPanelLink = $('.link-open-login-panel');
                        var loginLayer = $('#login-layer');

                        loginLayer.toggleClass('open');
                        loginPanelLink.data('text', '');
                        loginPanelLink.addClass('user-logged-in');
                        loginPanelLink.text(loginPanelLink.data('text'));
                        loginPanelLink.toggleClass('login-panel-open');

                        location.reload();

                        setTimeout(function() {
                            $('#login-layer').find('.login-layer-content').html($(data.loginForm).find('.login-layer-logout'));
                        }, 500);
                    }

                    removeAjaxLoader($('#login-layer').find('.login-layer'));
                },
                error: function(data, status, object){
                }
            });
        });

        $(document).on('click', 'a[href^="mailto:"]', function(e) {
            preventUnload = true;
        });

        // Show loader on page change
        $(window).on('beforeunload', function(e) {
            //console.log('before');
            if(!preventUnload) {
                $('#page-loader').fadeIn(500);
            } else {
                preventUnload = false;
            }
        });

        // Window resize events
        $(window).on('resize', function() {
            // if window width was changed
            if($(window).innerWidth() != windowWidth) {
                fixMobileAccordion();
                $('#mobile-nav').removeClass('open');
                $('#hamburger').removeClass('active');

                windowWidth = $(window).innerWidth();
                correctAdBanner();
            }

            resizeLoginLayerContent();
        });

        // Mobile Menu
        $('#hamburger').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();

            $('#hamburger').toggleClass('active');
            $('#mobile-nav').toggleClass('open');
            $('body').toggleClass('body-fixed');
        });

        // slide out mobile navigation if clicked outside
        $('#mobile-nav').on('click', function(e) {
            if($(e.target).hasClass('mobile-nav')) {
                $('#mobile-nav').removeClass('open');
                $('#hamburger').removeClass('active');
            }
        });

        // Slider functionality
        $(".slider-range").each(function() {
            var slider = $(this);
            var min = parseInt($(this).attr('data-formin'));
            var max = parseInt($(this).attr('data-formax'));

            if (slider.is('#rooms-range')) {
                max = 12;
            }

            if (slider.is('#size-range')) {
                //min = 50;
            }

            $(this).slider({
                range: true,
                min: min,
                max: max,
                values: [min, max],
                slide: function (event, ui) {
                    slider.closest('.ranges-area').find('input.min').val(ui.values[0]);
                    slider.closest('.ranges-area').find('input.max').val(ui.values[1]);
                    $(ui.handle).html(ui.values[$(ui.handle).index() - 1]);
                },
                create : function() {
                    if (slider.is('#size-range')) {
                        $('#size-range.slider-range').slider('option', 'step', 50);
                    }
                }
            });
            $(this).children('.ui-slider-handle').eq(0).html(min);
            $(this).children('.ui-slider-handle').eq(1).html(max);

        });

        // Toggle modal
        $('.toggle-modal').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            var target = $(this).attr('href');
            $(target).toggleClass('open');
            $('main').toggleClass('blur');
        });

        // Step functionality
        $('.step').on('click', function(e) {
            e.preventDefault();
            var self = $(this).closest('.step-tab');
            var target = '#' + $(this).attr('data-target');
            $(target).toggleClass('active');
            $(self).toggleClass('active');
        });

        // Image slider
        $( '.slider-pro' ).sliderPro({
            thumbnailWidth: 116,
            thumbnailHeight: 78,
            thumbnailsPosition: 'bottom',
            thumbnailPointer: true,
            thumbnailArrows: false,
            buttons: false,
            arrows: true,
            width: '100%',
            height: 400,
            centerImage: true,
            fullScreen: true,
            imageScaleMode: 'contain',
            autoHeight: true
        });

        // Image slider list
        $( '.slider-pro-list' ).sliderPro({
            buttons: false,
            arrows: true,
            width: '100%',
            //height: 200,
            centerImage: true,
            fullScreen: false,
            imageScaleMode: 'contain',
            autoHeight: false
        });

        // Image slider mini
        $( '.slider-pro-mini' ).sliderPro({
            buttons: false,
            arrows: false,
            width: '100%',
            height: 100,
            centerImage: true,
            fullScreen: false,
            imageScaleMode: 'contain',
            autoHeight: false,
            touchSwipe: false
        });

        // Add text to fullscreen button for image slider
        if($('.sp-full-screen-button').length > 0) {
            var fullScreenText = $('.full-screen-text').html();
            $('.sp-full-screen-button').html(fullScreenText);
        }

        // practice offer new : pratice type control
        $('#practice_form_practiceType label').on("click", function(e) {
            var id = $(this).attr('for');
            id = id.replace('practice_form_', '');

            $('.practice-offer-new .attribute-wrapper').each(function(){
                // if has class and is not visible
                if ($(this).hasClass(id) && !$(this).is(':visible')) {
                    $(this).fadeIn();
                }

                // if has not class
                if (!$(this).hasClass(id)) {
                    $(this).fadeOut();
                }

                if ($(this).find('#practice_form_shortDescription').length && id == 'practiceType_1') {
                    $(this).find('#practice_form_shortDescription').attr('placeholder', $(this).data('altdesc'));
                } else {
                    $(this).find('#practice_form_shortDescription').attr('placeholder', $(this).data('defaultdesc'));
                }
            });

        });

        // if user on page practice offer new
        if ($('.practice-offer-new').length > 0) {
            // trigger click on first radio label
            var checkedRadio = $('#practice_form_practiceType input[type="radio"]:checked');
            if (checkedRadio.length) {
                checkedRadio.siblings('label').trigger('click');
            } else {
                $('#practice_form_practiceType .radio').first().find('label').trigger('click');
            }
        }

        // arrow on startpage
        $('.startpage .arrow-down a').on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
            $('html, body').stop().animate({
                scrollTop: $(document).height()
            }, 500);
        });

        // admin set active/inactive in list element
        $('body').on('click', '.admin-set-active', function(e) {
            e.preventDefault();
            e.stopPropagation();

            addAjaxLoader();

            var that = $(this);

            $.post(that.attr('href'), {}, function(data) {
                if(data.data.active) {
                    if(that.closest('.device-result').length) {
                        that.closest('.device-result').removeClass('inactive');
                    } else {
                        if(that.closest('.job-result').length) {
                            that.closest('.job-result').removeClass('inactive');
                        } else {
                            that.closest('.row').removeClass('inactive');
                        }
                    }
                    that.find('.glyphicon').removeClass('glyphicon-eye-open');
                    that.find('.glyphicon').addClass('glyphicon-eye-close');
                } else {
                    if(that.closest('.device-result').length) {
                        that.closest('.device-result').addClass('inactive');
                    } else {
                        if(that.closest('.job-result').length) {
                            that.closest('.job-result').addClass('inactive');
                        } else {
                            that.closest('.row').addClass('inactive');
                        }                    }
                    that.find('.glyphicon').addClass('glyphicon-eye-open');
                    that.find('.glyphicon').removeClass('glyphicon-eye-close');
                }
            }).always(function() {
                removeAjaxLoader();
            });
        });

        activateFlashMessages();

        $('.file-upload').on('change', function(e) {
            var fakeForm = new FormData;

            addAjaxLoader();

            fakeForm.append('file[]', $(this)[0].files[0]);

            $.ajax({
                url: 'datei/upload',
                type: 'POST',
                data: fakeForm,
                cache: false,
                dataType: 'json',
                processData: false, // Don't process the files
                contentType: false, // Set content type to false as jQuery will tell the server its a query string request
                success: function(data, textStatus, jqXHR)
                {
                    $.each(data.data.files, function(key, value) {
                        var container = $(
                            '<div class="row image-row">' +
                            '<img src="datei/show/' + value.file + '" class="image-responsive" alt="' + value.originalName + '" style="width: 120px;" data-filename="' + value.file + '"> <span class="image-name">' + value.originalName + '</span>' +
                            '<input type="hidden" name="file[]" value="' + value.fileId + '" />' +
                            '<div class="remove-image"></div>' +
                            '</div>'
                        );

                        container.find('.remove-image').on('click', function() {
                            var that = $(this);

                            addAjaxLoader();

                            $.ajax({
                                url: 'datei/remove/' + value.file,
                                type: 'POST',
                                data: {},
                                cache: false,
                                dataType: 'json',
                                success: function(datarem) {
                                    that.closest('.image-row').remove();
                                    removeAjaxLoader();
                                },
                                error: function() {
                                    removeAjaxLoader();
                                }
                            });
                        });
                        $('.file-upload-container').append(container);
                    });
                    removeAjaxLoader();
                },
                error: function(jqXHR, textStatus, errorThrown)
                {
                    // Handle errors here
                    //console.log('ERRORS: ' + textStatus);
                    removeAjaxLoader();
                }
            });
        });

        $('.add-image').on('click', function() {
            $('.file-upload').trigger('click');
        });



        // PDF generating
        $(document).on('click', '.download-pdf', function(e) {
            e.preventDefault();
            // reset image
            $('.pdf-form [name="img"]').val('');

            // if is practice detail
            if ($('body.practice-detail').length) {
                $('.image-area .sp-slide img').each(function() {
                    // if is set
                    if ($('.pdf-form [name="img"]').val().length) {
                        // append comma
                        $('.pdf-form [name="img"]').val($('.pdf-form [name="img"]').val() + ',');
                    }
                    // append img src
                    $('.pdf-form [name="img"]').val($('.pdf-form [name="img"]').val() + $(this).attr('src'));
                });
            } else {
                $('.pdf-form [name="img"]').val($('.image-area img').first().attr('src'));
            }

            $('.details-list li').each(function() {
                $(this).html($.trim($(this).html()));
            });

            var text = $('<div>').append($('.details-list').clone()).html() + '</p><p>';
            $('.detail-description .description').each(function() {
                text += '</p><p>' + $.trim($(this).html()) + '</p><p>';
            });
            text += '</p><p>' + $.trim($('.detail-description .additional-information').html()) + '</p><p>';

            $('.pdf-form [name="text"]').val(encodeURIComponent(text));
            $('.pdf-form').trigger('submit');

            //var doc = new jsPDF('p', 'mm', 'a4');
            //
            //// We'll make our own renderer to skip this editor
            //var specialElementHandlers = {
            //    '.ignore-pdf': function(element, renderer){
            //        return true;
            //    },
            //    'img': function(element, renderer) {
            //        var width = doc.internal.pageSize.width;
            //        var height = doc.internal.pageSize.height;
            //
            //        return doc.addImage(imgData, 'JPEG', 0, 0, 120, 60);
            //    }
            //};
            //
            ////console.log($('.detail-view > .container').html());
            //
            //// All units are in the set measurement for the document
            //// This can be changed to "pt" (points), "mm" (Default), "cm", "in"
            //doc.fromHTML($('.detail-view > .container')[0],15,15,{
            //    'width': 180,
            //    'elementHandlers': specialElementHandlers
            //},
            //function() {
            //    //doc.save('test.pdf');
            //    doc.output('dataurlnewwindow');
            //});
        });

        $('form[name="practice_form"] button[type="submit"]').on('click', function(e) {
            if(validatePracticeForm()) {
            } else {
                e.preventDefault();
            }
        });

        $('.reset-form').on('click', function() {
            resetSearchForm($(this).closest('form'));
        });

        if($('body').hasClass('startpage')) {
            // animate padding on startpage searchbox
            var startpageSearchField = $('.startpage .search input');
            var placeholderLength = startpageSearchField.attr('placeholder').length;

            // initially center placeholder
            startpageSearchField.css('padding-left', '50%').css('padding-left', '-=' + (placeholderLength * 9) / 2);

            // when focussed, align text left
            startpageSearchField.on('focus', function () {
                $(this).stop().animate({
                    'padding-left': 6
                }, 300);
            });

            // when not focussed, center text
            startpageSearchField.on('focusout', function () {
                var charLength = $(this).val().length;
                var newWidth = (charLength * 9) / 2;
                if (charLength > 0) {
                    // startpageSearchField.stop().animate({
                    //     'padding-left': startpageSearchField.width()/2 -newWidth + 6
                    // }, 300);
                } else {
                    startpageSearchField.stop().animate({
                        'padding-left': startpageSearchField.width() / 2 - (placeholderLength * 9) / 2 + 6
                    }, 300);
                }
            });
        }

        $('.other-checkbox').click(function() {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).closest('.other-checkbox-wrap').find('input[type="text"]').attr('disabled', 'disabled');
                $(this).closest('.other-checkbox-wrap').find('input[type="text"]').val('');
            } else {
                $(this).addClass('active');
                $(this).closest('.other-checkbox-wrap').find('input[type="text"]').removeAttr('disabled');
            }
        });

        $('.file-upload-container').find('.remove-image').on('click', function() {
            var that = $(this);

            addAjaxLoader();

            $.ajax({
                url: 'datei/remove/' + that.attr('data-file'),
                type: 'POST',
                data: {},
                cache: false,
                dataType: 'json',
                success: function (datarem) {
                    that.closest('.image-row').remove();
                    removeAjaxLoader();
                },
                error: function () {
                    removeAjaxLoader();
                }
            });
        });
        $(".closebutton").click(function(e){
            e.preventDefault();

            $(this).closest('.bannerlink').hide();
        });

        if (!$(".search-results")[0]){
            $(".closebutton").hide();
        }


    });
    window.onpageshow = function() {
        $('#page-loader').fadeOut(500);
        userRemoveEntry();
        ajaxLinks();
    };
})(jQuery);
