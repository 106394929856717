$.fn.isVisible = function() {
    // Am I visible?
    // Height and Width are not explicitly necessary in visibility detection, the bottom, right, top and left are the
    // essential checks. If an image is 0x0, it is technically not visible, so it should not be marked as such.
    // That is why either width or height have to be > 0.
    var rect = this[0].getBoundingClientRect();

    if(this.css('display') == 'none') {
        return false;
    }

    return (
        (rect.height > 0 || rect.width > 0) &&
        rect.bottom >= 0 &&
        rect.right >= 0 &&
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

function correctLoginLayer() {
    if($(document).scrollTop() <= 50) {
        $('.login-layer-container').css({
            'right': ($(window).width() - ($('.navigation-left').offset().left + $('.navigation-left').innerWidth())) - 15
        });
    }
}

function correctAdBanner() {
    $('.ad-box .column').css({
        'right': ($(window).width() - ($('.navigation-left').offset().left + $('.navigation-left').innerWidth())) - 15,
        'opacity': 1
    });
}

function resizeLoginLayerContent() {
    // delete height
    $('.login-layer-scroll').css('height', '');
    if ($(window).innerHeight() < $('.login-layer-scroll').height() + 70) {
        // set new height
        $('.login-layer-scroll').css('height', $(window).innerHeight() - 80);
    }
}

function addAjaxLoader(domElement) {
    //var ajaxLoader = $('<div class="ajax-loader"><i class="glyphicon glyphicon-refresh"></i></div>');
    //domElement.append(ajaxLoader);
    $('#page-loader').fadeIn(500);
}

function removeAjaxLoader(domElement) {
    //domElement.find('.ajax-loader').remove();
    $('#page-loader').fadeOut(500);
}

function fixMobileAccordion() {
    $('a[data-toggle="collapse"]').each(function(){
        var target = $(this).attr('href');
        $(target).collapse('hide');
    });
    if($(window).width() < 768) {
        $('a[data-toggle="collapse"]').each(function() {
            var target = $(this).attr('aria-controls');
            var desktopParent = '#' + target;
            var mobileParent = '#' + target + '-mobile';
            if ($(desktopParent).length > 0) {
                $(mobileParent).append($(desktopParent).html());
                $(desktopParent).empty();
                $(mobileParent).find('.close-panel-button').attr('href', mobileParent);
            }
            $(this).attr('href', '#'+target+'-mobile');
        });
    } else {
        $('a[data-toggle="collapse"]').each(function() {
            var target = $(this).attr('aria-controls');
            var desktopParent = '#' + target;
            var mobileParent = '#' + target + '-mobile';
            if ($(mobileParent).length > 0) {
                $(desktopParent).append($(mobileParent).html());
                $(mobileParent).empty();
                $(desktopParent).find('.close-panel-button').attr('href', desktopParent);
            }
            $(this).attr('href', '#'+target);
        });
    }
}

function loadMoreResults(form, url, index, number, targetContainer) {
    form = form.serialize();

    $('.ajax-scroll-loader .uil-default-css').animate({
        opacity: 1
    });

    url = url + '/' + index + '/' + number;

    jQuery.get(url, form, function(data){
        var newContent = $(data.data.html);

        newContent.appendTo(targetContainer);
        newContent.find( '.slider-pro-list' ).sliderPro({
            buttons: false,
            arrows: true,
            width: '100%',
            //height: 200,
            centerImage: true,
            fullScreen: false,
            imageScaleMode: 'contain',
            autoHeight: false
        });

        setTimeout(function() {
            targetContainer.find('.practice-result, .device-result').addClass('faded-in');
        }, 50);

        if(data.data.html.length > 0) {
            $('.ajax-scroll-loader .uil-default-css').animate({
                opacity: 0
            });
            $('.in-view-checker').show();
        } else {
            $('.ajax-scroll-loader').addClass('loader-hidden');
        }
    }, 'json');
}

function activateFlashMessages() {
    var flashMessages = $('.flash-message-open');

    if(flashMessages.length) {
        flashMessages.toggleClass('open');
        $('main').toggleClass('blur');
    }
}

function validatePracticeForm() {
    var error = false;
    var scrolled = false;

    var practiceType = $('input[name="practice_form[practiceType]"]:checked').val();
    var title = $('#practice_form_title');
    var country = $('#practice_form_country');
    var zipCode = $('#practice_form_zipCode');
    var state = $('#practice_form_state');

    title.removeClass('ws-error');
    country.next('.select2').removeClass('ws-error');
    zipCode.removeClass('ws-error');
    state.next('.select2').removeClass('ws-error');

    if(title.val().length < 1) {
        title.addClass('ws-error');
        error = true;

        if(!scrolled) {
            $('html, body').animate({scrollTop: title.offset().top -100 }, 'slow');
            scrolled = true;
        }
    }

    if(country.val().length < 1) {
        country.next('.select2').addClass('ws-error');
        error = true;

        if(!scrolled) {
            $('html, body').animate({scrollTop: country.offset().top -100 }, 'slow');
            scrolled = true;
        }
    }

    if(zipCode.val().length < 1) {
        zipCode.addClass('ws-error');
        error = true;

        if(!scrolled) {
            $('html, body').animate({scrollTop: zipCode.offset().top -100 }, 'slow');
            scrolled = true;
        }
    }

    if(state.val().length < 1) {
        state.next('.select2').addClass('ws-error');
        error = true;

        if(!scrolled) {
            $('html, body').animate({scrollTop: state.offset().top -100 }, 'slow');
            scrolled = true;
        }
    }

    return true;
}

function resetSearchForm(form) {
    form.find('input[type="text"]').not('.hidden').val('');
    form.find('input[type="text"]').not('.hidden').attr('value', '');
    form.find('select option:selected').val(null).trigger('change');
    form.find('select option:selected').removeAttr('selected');
    form.find('select').trigger('change');
    form.find('.tab-style-radio').find('.radio input:checked').removeAttr('checked');
    form.find('.tab-style-radio').find('.radio:first-of-type input[type="radio"]').attr('checked', 'checked');
    form.find('.slider-range').each(function() {
        var sliderOptions = $(this).slider('option');
        $(this).slider('values', [sliderOptions.min, sliderOptions.max]);

        var handles = $(this).find('.ui-slider-handle');

        $(handles[0]).html(sliderOptions.min);
        $(handles[1]).html(sliderOptions.max);
    });
}

function userRemoveEntry() {
    var $ = jQuery;

    $(document).on('click', '.user-remove-entry', function(e) {
        e.preventDefault();
        e.stopPropagation();

        var target = $('#remove-modal');
        var that = $(this);

        target.find('.chiffre-container').text(that.attr('data-chiffre'));
        target.find('.ajax-link').attr('data-path', that.attr('data-remove'));

        target.toggleClass('open');
        $('main').toggleClass('blur');

        target.find('.user-reload-page').on('click', function() {
            location.reload();
        });
    });
}


function ajaxLinks() {
    var $ = jQuery;

    // ajax links
    $(document).on('click', '.ajax-link', function(e) {
        var path = $(this).attr('data-path');
        var prevent = $(this).attr('data-prevent');
        var method = $(this).attr('data-method');

        addAjaxLoader();

        if(path.length > 0) {
            if(prevent == 'true') {
                e.preventDefault();
            }

            if(method == 'post') {
                dataObj = {};

                if($('#ask-email').length > 0) {
                    dataObj.email = $('#ask-email').val();
                }

                if($('#ask-text')) {
                    dataObj.text = $('#ask-text').val();
                }

                if($('#ask-name')) {
                    dataObj.name = $('#ask-name').val();
                }

                if($('#ask-phone')) {
                    dataObj.phone = $('#ask-phone').val();
                }

                if($('#send-request-message').length) {
                    dataObj.message = $('#send-request-message').val();
                }

                $.post(path, dataObj, function (data) {
                    //console.log(data);
                }).always(function () {
                    removeAjaxLoader();
                });
            } else {
                $.get(path, function (data) {
                    //console.log(data);
                }).always(function () {
                    removeAjaxLoader();
                });
            }

        } else {
            removeAjaxLoader();
        }
    });
}
